document.addEventListener("DOMContentLoaded", function () {
  initOffCanvas();
});

function initOffCanvas() {
  var menuContainer = document.querySelector(".off-canvas.burger-menu");
  var menuToggleBtns = document.querySelectorAll(
    "#burger-menu, .burger-menu__close"
  );

  var bookingContainer = document.querySelector(".off-canvas.booking-menu");
  var bookingToggleBtns = document.querySelectorAll(
    '[data-toggle="booking-menu"], .booking-menu__close'
  );

  var state = {
    isActive: false,
    toggleIsActive: function () {this.isActive = !this.isActive}
  }

  handleOffCanvas(menuContainer, menuToggleBtns, state);
  handleOffCanvas(bookingContainer, bookingToggleBtns, state);

  function handleOffCanvas(container, toggleBtns, state) {
    var handleToggle = function (state, container) {
      if (!state) {
        container.style.transform = "translateX(-100%)";
        document.querySelector("body").classList.remove("is-off-canvas-open");
      } else {
        container.style.transform = "translateX(0)";
        document.querySelector("body").classList.add("is-off-canvas-open");
      }
    };
    toggleBtns.forEach(function (btn) {
      btn.addEventListener("click", function () {
        state.toggleIsActive()
        handleToggle(state.isActive, container);
      });
    });


  }

  if (bookingContainer) {
    bookingContainer.addEventListener('click', function (event) {
      if (event.target.dataset.container !== 'true') return;
      state.toggleIsActive()
      bookingContainer.style.transform = "translateX(-100%)";
      document.querySelector("body").classList.remove("is-off-canvas-open");
    })
  }
}
